import { Navigate, useOutlet } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { ManagerProvider } from "../hooks/useManagerProvider";

export const ManagerPage = () => {
  const { user } = useAuth();
  const outlet = useOutlet();

  if (!user?.roles?.includes('ROLE_MANAGER')) {
    return <Navigate to="/dashboard" />;
  }

  return <ManagerProvider>
      {outlet}
    </ManagerProvider>  
};
