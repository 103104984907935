import { LoadingButton } from '@mui/lab';
import { Grid } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { MobileDateTimePicker } from '@mui/x-date-pickers';
import { styled } from '@mui/system'
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { FormContainer, TextFieldElement } from 'react-hook-form-mui'
import { TextFieldElementPreventEnter } from './TextFieldElementPreventEnter';

const GridFormItem = styled(Grid)({
  paddingInline: 10
});

GridFormItem.defaultProps = {
  item: true,
  md: 6
}

export const ClientForm = ({ defaultValues, submitAction, submitText, onSubmitStateUpdate }) => {
  const defaultBirthdate = defaultValues?.birthDate || null;
  const [birthDate, setBirthDate] = useState(defaultBirthdate);
  const formContext = useForm({ defaultValues });
  const isSubmitting = formContext.formState.isSubmitting;

  useEffect(() => {
    onSubmitStateUpdate(isSubmitting);
  }, [onSubmitStateUpdate, isSubmitting]);

  return (
    <FormContainer formContext={formContext} onSuccess={formContext.handleSubmit((data) => submitAction({ ...data, birthDate }))}>
      <Grid container>
          <GridFormItem>
            <TextFieldElement
              margin="normal"
              fullWidth
              id="name"
              label="Nume"
              name="name"
              autoComplete="off"
              autoFocus
              required
              parseError={(error) => {
                return error.type === 'required' ? 'Introduceti numele' : error.message
              }}
            />
          </GridFormItem>
          <GridFormItem>
            <TextFieldElementPreventEnter
              margin="normal"
              fullWidth
              id="badge"
              label="Legitimatie"
              name="badge"
              required
              validation={{ validate: (value) => !value || value > 0 }}
              autoComplete="off"
              disabled={!!defaultValues?.badge}
              parseError={() => 'Introduceti o legitimatie valida'}
            />
          </GridFormItem>
          <GridFormItem>
            <TextFieldElement
              margin="normal"
              fullWidth
              id="client"
              label="Cod client"
              name="client"
              validation={{ validate: (value) => !value || value > 0 }}
              parseError={() => 'Introduceti un cod client valid'}
              autoComplete="off"
            />
          </GridFormItem>
          <GridFormItem>
            <TextFieldElement
              margin="normal"
              fullWidth
              id="pin"
              label="CNP"
              name="pin"
              validation={{ validate: (value) => !value || value > 0 }}
              autoComplete="off"
              parseError={() => 'Introduceti un CNP valid'}
            />
          </GridFormItem>
          <GridFormItem>
            <TextFieldElement
              margin="normal"
              fullWidth
              id="phone"
              label="Telefon"
              name="phone"
              validation={{ validate: (value) => !value || value > 0 }}
              autoComplete="off"
              parseError={() => 'Introduceti un numar de telefon valid'}
            />
          </GridFormItem>
          <GridFormItem>
            <TextFieldElement
              margin="normal"
              fullWidth
              id="address"
              label="Adresa"
              name="address"
              autoComplete="off"
              parseError={() => 'Introduceti adresa'}
            />
          </GridFormItem>
          <GridFormItem>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <MobileDateTimePicker
                label="Data nastere"
                inputFormat="dd/MM/yyyy"
                value={birthDate}
                name="birthdate"
                onChange={setBirthDate}
                renderInput={(params) => <TextFieldElement autoComplete="off" name="birthdate" value={birthDate} fullWidth {...params} inputProps={{...params.inputProps, readOnly: true}} sx={{ marginTop: '16px', marginBottom: '8px' }}/>}
              />
            </LocalizationProvider>
          </GridFormItem>
          <GridFormItem>
            <TextFieldElement
              margin="normal"
              fullWidth
              id="email"
              type="email"
              label="Email"
              name="email"
              autoComplete="off"
              parseError={() => 'Introduceti un email valid'}
            />
          </GridFormItem>
          <GridFormItem md={12}>
            <LoadingButton
              variant='contained'
              fullWidth
              type='submit'
              loading={isSubmitting}
              sx={{ py: 2, mt: '1rem' }}
            >
              {submitText}
            </LoadingButton>
          </GridFormItem>
      </Grid>
    </FormContainer>
  )
}
