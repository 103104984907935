const serialize = (session) => {
  const tags = [];
  const subscription = session.subscription;
  let tagIndex = 0;

  delete session.badge;

  while(session[`tag${tagIndex}`]) {
    tags.push(session[`tag${tagIndex}`]);
    delete session[`tag${tagIndex}`];
    tagIndex++;
  }

  return { 
    ...session,
    entry: session.entry?.id,
    client: subscription?.client.id || session.client?.id,
    tag: session.tag || (tags.length === 1 ? tags[0] : null),
    tags: tags.length > 1 ? tags : null,
    tagLost: !!session.tagLost,
    keyLost: !!session.keyLost,
    overtime: session.overtime || 0,
    price: session.price || 0,
    subscription: subscription?.id
  };
};

export { serialize };
