import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { SnackbarProvider } from 'notistack';
import { CssBaseline } from '@mui/material';
import { ThemeProvider as MUIThemeProvider, createTheme, StyledEngineProvider } from '@mui/material/styles';

import palette from './palette';
import shadows from './shadows';
import typography from './typography';
import GlobalStyles from './globalStyles';
import customShadows from './customShadows';
import componentsOverride from './overrides';
import { TextFieldElement } from 'react-hook-form-mui';

ThemeProvider.propTypes = {
  children: PropTypes.node,
};

TextFieldElement.defaultProps = {
  onWheel: (event) => event.target.blur()
};

export default function ThemeProvider({ children }) {
  const themeOptions = useMemo(
    () => ({
      palette,
      shape: { borderRadius: 6 },
      typography,
      shadows: shadows(),
      customShadows: customShadows(),
    }),
    []
  );

  const theme = createTheme(themeOptions);
  theme.components = componentsOverride(theme);

  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles />
        <SnackbarProvider maxSnack={3}>
          {children}
        </SnackbarProvider>
      </MUIThemeProvider>
    </StyledEngineProvider>
  );
}
