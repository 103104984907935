import { TopMenu } from "../../components/TopMenu";
import { Button, Card, Table, TableContainer, TableBody, TableRow, TableCell, Typography } from "@mui/material";
import { useLocation, useNavigate, useOutlet } from "react-router-dom";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import { useEffect, useState } from "react";
import API from '../../api/client';
import TableColumns from "../../components/TableColumns";

const TABLE_HEAD = [
  { id: 'username', label: 'Utilizator', alignRight: false },
  { id: 'edit', label: '' }
];

export const UsersPage = () => {
  const navigate = useNavigate();
  const outlet = useOutlet();
  const location = useLocation();
  const [users, setUsers] = useState([]);

  useEffect(() => {
    if (location.pathname !== '/dashboard/manager/users') {
      return;
    }
    
    const controller = new AbortController();

    API.get('admin/users', { signal: controller.signal })
      .then(response => setUsers(response.data))
      .catch(error => console.log(error));
    
    return () => {
      controller.abort();
    };
  }, [location.pathname]);

  return (
    <>
      <TopMenu title="Utilizatori">
        <Button variant="contained" startIcon={<AddIcon />} onClick={() => navigate('/dashboard/manager/users/new')}>
          Utilizator Nou
        </Button>
      </TopMenu>
      <Card sx={{ display: 'flex', flexDirection: 'column', minHeight: 0 }}>
        <TableContainer sx={{ minWidth: 800, flexGrow: 1 }}>
          <Table stickyHeader>
            <TableColumns
              headLabel={TABLE_HEAD}
              rowCount={users.length}
            />
            <TableBody>
              {users.map((user) => {
                return (
                  <TableRow hover key={user.id} tabIndex={-1} onClick={() => navigate(`/dashboard/manager/users/${user.id}`)}>
                    <TableCell component="th" scope="row">
                      <Typography variant="subtitle2" noWrap>
                        {user.username}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <EditIcon />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
      {outlet}
    </>
  );
};
