import { Grid } from "@mui/material";
import { useForm } from "react-hook-form";
import { FormContainer, TextFieldElement } from "react-hook-form-mui";
import { LoadingButton } from "@mui/lab";
import { useEffect } from "react";
import OPTIONS from '../constants/options';

export const OptionForm = ({ defaultValues, submitAction, submitText, onSubmitStateUpdate }) => {
  const formContext = useForm({ defaultValues });
  const isSubmitting = formContext.formState.isSubmitting;

  useEffect(() => {
    onSubmitStateUpdate(isSubmitting);
  }, [onSubmitStateUpdate, isSubmitting]);

  return (
    <FormContainer formContext={formContext} onSuccess={formContext.handleSubmit(submitAction)}>
      <Grid container spacing={1} minWidth={500} maxWidth={500}>
        <Grid item md={12}>
          <TextFieldElement
            margin="normal"
            fullWidth
            id="value"
            label={OPTIONS[defaultValues.type]}
            name="value"
            autoComplete="off"
            type="number"
            required
            autoFocus
            parseError={() => 'Introduceti valoarea'}
          />
        </Grid>
        <Grid item md={12}>
          <LoadingButton
            variant='contained'
            fullWidth
            type='submit'
            loading={isSubmitting}
            sx={{ py: 2, mt: '1rem' }}
          >
            {submitText}
          </LoadingButton>
        </Grid>
      </Grid>
    </FormContainer>
  );
};
