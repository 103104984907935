import { useOutlet, useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect, useCallback } from 'react';
import {
  Card,
  Table,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  TablePagination,
} from '@mui/material';

import AddIcon from '@mui/icons-material/Add';

import SearchToolbar from "../../components/SearchToolbar";
import TableColumns from "../../components/TableColumns";

import API from '../../api/client';
import { useSnackbar } from 'notistack';
import { TopMenu } from "../../components/TopMenu";
import { format, formatDistance, differenceInMinutes, parse } from 'date-fns'
import { ro } from 'date-fns/locale';

const TABLE_HEAD = [
  { id: 'tag', label: 'Bratara', alignRight: false },
  { id: 'badge', label: 'Legitimatie', alignRight: false },
  { id: 'startDate', label: 'Ora intrare', alignRight: false, isSortable: true },
  { id: 'remainingTime', label: 'Timp ramas', alignRight: false, isSortable: true },
];

export const SessionsPage = () => {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('startDate');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sessions, setSessions] = useState([]);
  const [filteredSessions, setFilteredSessions] = useState([]);
  const [reload, setReload] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const outlet = useOutlet();
  const navigate = useNavigate();
  const location = useLocation();

  const handleRequestSort = useCallback((_, property) => {
    const currentOrder = orderBy === property ? order : 'desc';
    const transition = {
      'asc': 'desc',
      'desc': 'asc'
    };
    const nextOrder = transition[currentOrder];

    setOrder(nextOrder);
    setOrderBy(nextOrder ? property : null);
    setPage(0);
  }, [order, orderBy]);

  const handleChangePage = useCallback((_, newPage) => {
    setPage(newPage);
  }, []);

  const handleChangeRowsPerPage = useCallback((value) => {
    setPage(0);
    setRowsPerPage(parseInt(value, 10));
  }, []);

  const handleSearch = useCallback((search) => {
    const session = sessions.find(session => session.tag === search || session.client?.badge === search);

    if (!session) {
      return enqueueSnackbar(`Bratara sau legitimatia nu a fost gasita`, { variant: 'error' });
    }
    
    navigate(`/dashboard/sessions/${session.id}`)
  }, [sessions, navigate, enqueueSnackbar]);

  
  useEffect(() => {
    if (location.pathname !== '/dashboard/sessions') {
      return;
    }
    
    const controller = new AbortController();
    const timeout = setTimeout(() => setReload(!reload), 60000);
     
    API.get('sessions', { signal: controller.signal, retry: 3, retryDelay: 3000 })
      .then((response) => {
        setSessions(response.data);
      })
      .catch(error =>{
        if (error.name === 'CanceledError') return;
        enqueueSnackbar(`Intrarile nu au putut fi incarcate: ${error.message}`, { variant: 'error' });
      })

    return () => {
      clearTimeout(timeout);
      controller.abort();
    };
  }, [reload, enqueueSnackbar, location.pathname]);

  useEffect(() => {
    const ordering = {
      'startDate': (a, b) => {
        const startDateA = parse(a.startedAt, 'yyyy-MM-dd HH:mm', new Date());
        const startDateB = parse(b.startedAt, 'yyyy-MM-dd HH:mm', new Date());
        const difference = differenceInMinutes(startDateA, startDateB);

        return order === 'asc' ? difference : -difference;
      },
      'remainingTime': (a, b) => {
        const endsAtA = parse(a.endsAt, 'yyyy-MM-dd HH:mm', new Date());
        const endsAtB = parse(b.endsAt, 'yyyy-MM-dd HH:mm', new Date());
        const difference = differenceInMinutes(endsAtA, endsAtB);

        return order === 'asc' ? difference : -difference;
      },
      null: () => 0
    };

    setFilteredSessions(sessions.sort(ordering[orderBy]).slice(page * rowsPerPage, (page + 1) * rowsPerPage));
  }, [page, sessions, rowsPerPage, order, orderBy]);

  return (
    <>
      <TopMenu title="Intrari">
        <Button variant="contained" startIcon={<AddIcon />} onClick={() => navigate('/dashboard/sessions/subscription')}>
          Abonament Nou
        </Button>
        <Button variant="contained" startIcon={<AddIcon />} onClick={() => navigate('/dashboard/sessions/new')}>
          Intrare Noua
        </Button>
      </TopMenu>

      <Card sx={{ display: 'flex', flexDirection: 'column', minHeight: 0 }}>
        <SearchToolbar onSearchText={handleSearch} />

        <TableContainer sx={{ minWidth: 800, flexGrow: 1 }}>
          <Table stickyHeader>
            <TableColumns
              order={order}
              orderBy={orderBy}
              headLabel={TABLE_HEAD}
              rowCount={sessions.length}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {filteredSessions.map((row) => {
                const { id, tag, client, startedAt, endsAt } = row;
                const parsedStartedAt = parse(startedAt, 'yyyy-MM-dd HH:mm', new Date());
                const parsedEndsAt = parse(endsAt, 'yyyy-MM-dd HH:mm', new Date());
                const startDate = format(parsedStartedAt, 'HH:mm (dd/MM/yyyy)');
                const expiryTime = formatDistance(parsedEndsAt, new Date(), { locale: ro });
                const expiryInMins = differenceInMinutes(parsedEndsAt, new Date());
                
                let expiryColor = 'green';

                if (expiryInMins < 15) expiryColor = 'orange';
                if (expiryInMins < 0) expiryColor = 'error';
                
                return (
                  <TableRow hover key={id} tabIndex={-1} onClick={() => navigate(`/dashboard/sessions/${id}`)}>
                    <TableCell component="th" scope="row">
                      <Typography variant="subtitle2" noWrap>
                        {tag}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">{client?.badge}</TableCell>
                    <TableCell align="left">{startDate}</TableCell>
                    <TableCell align="left">
                      <Typography color={expiryColor}>{expiryInMins < 0 ? '-' : ''} {expiryTime}</Typography>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          labelRowsPerPage={'Randuri pe pagina'}
          labelDisplayedRows={({ from, to, count }) => `${from}-${to} din ${count}`}
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={sessions.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={(event) => handleChangeRowsPerPage(event.target.value)}
          sx={{ minHeight: 64, boxShadow: 6 }}
        />
      </Card>
      {outlet}
    </>
  );
};
