import { useNavigate, useParams } from "react-router-dom";
import API from '../../../api/client';
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { UserForm } from "../../../components/UserForm";
import { AppDialog } from "../../../components/AppDialog";
import { usePreloader } from "../../../hooks/usePreloader";

export const EditUserPage = () => {
  const [user, setUser] = useState();
  const { id } = useParams();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { showPreloader } = usePreloader();

  useEffect(() => {
    showPreloader(true);

    API.get(`admin/users/${id}`)
      .then(response => setUser(response.data))
      .catch(error => {
        enqueueSnackbar(`Utilizatorul nu exista sau nu a putut fi incarcat: ${error.message}`, { variant: 'error' });

        throw error;
      })
      .finally(() => showPreloader(false))
  }, [id, enqueueSnackbar, showPreloader]);

  const submitUser = (data) => {
    return API.put(`admin/users/${id}`, data)
      .then(() => enqueueSnackbar(`Utilizatorul a fost modificat cu succes`, { variant: 'success' }))
      .catch(error => {
        enqueueSnackbar(`Utilizatorul nu a putut fi modificat: ${error.message}`, { variant: 'error' });
      });
  }

  return (
    <AppDialog title="Modificare utilizator" preventClose={isSubmitting}>
      {user &&
        <UserForm defaultValues={user} submitAction={(data) => submitUser(data).then(() => navigate(-1))} submitText="Modifica utilizator" onSubmitStateUpdate={setIsSubmitting} />
      }
    </AppDialog>
  );
};
