import { Grid } from "@mui/material";
import { useForm } from "react-hook-form";
import { AutocompleteElement, FormContainer, TextFieldElement } from "react-hook-form-mui";
import { LoadingButton } from "@mui/lab";
import { useEffect } from "react";

export const UserForm = ({ defaultValues, submitAction, submitText, onSubmitStateUpdate }) => {
  const formContext = useForm({ defaultValues });
  const password = formContext.watch('password');
  const isSubmitting = formContext.formState.isSubmitting;

  useEffect(() => {
    onSubmitStateUpdate(isSubmitting);
  }, [onSubmitStateUpdate, isSubmitting]);

  return (
    <FormContainer formContext={formContext} onSuccess={formContext.handleSubmit(submitAction)}>
      <Grid container spacing={1} minWidth={500} maxWidth={500}>
        <Grid item md={12}>
          <TextFieldElement
            margin="normal"
            fullWidth
            id="username"
            label="Utilizator"
            name="username"
            autoComplete="off"
            required
            autoFocus
            disabled={!!defaultValues?.username}
            parseError={() => 'Introduceti nume utilizator'}
          />
        </Grid>
        <Grid item md={12}>
          <TextFieldElement
            margin="normal"
            fullWidth
            id="password"
            label="Parola"
            type={'password'}
            name="password"
            autoComplete="off"
            required
            parseError={() => 'Introduceti parola'}
          />
        </Grid>
        <Grid item md={12}>
          <TextFieldElement
            margin="normal"
            fullWidth
            id="confirmPassword"
            label="Confirma Parola"
            type={'password'}
            name="confirmPassword"
            autoComplete="off"
            required
            validation={{ validate: (value) => value === password }}
            parseError={() => 'Introduceti din nou parola'}
          />
        </Grid>
        <Grid item md={12}>
          <AutocompleteElement
            multiple
            label="Roluri"
            name="roles"
            options={['ROLE_USER', 'ROLE_MANAGER']}
            autocompleteProps={{
              disabled: !!defaultValues?.username,
              isOptionEqualToValue: (option, value) => `${option}` === `${value}`
            }}
          />
        </Grid>
        <Grid item md={12}>
          <LoadingButton
            variant='contained'
            fullWidth
            type='submit'
            loading={isSubmitting}
            sx={{ py: 2, mt: '1rem' }}
          >
            {submitText}
          </LoadingButton>
        </Grid>
      </Grid>
    </FormContainer>
  );
};
