import { Grid, Typography } from '@mui/material';
import { LoadingButton } from "@mui/lab";
import { useForm } from "react-hook-form";
import { AutocompleteElement, FormContainer } from 'react-hook-form-mui'
import { useMemo, useEffect } from "react";
import debounce from 'lodash.debounce';
import { useDashboardProvider } from '../hooks/useDashboardProvider';
import { TextFieldElementPreventEnter } from './TextFieldElementPreventEnter';
import { usePreviousValue } from '../hooks/usePreviousValue';

export const SessionForm = ({ defaultValues, subscriptions, submitAction, submitText, onSubmitStateUpdate, onBadgeUpdate }) => {
  const formContext = useForm({ defaultValues });
  const isSubmitting = formContext.formState.isSubmitting;
  const { entries } = useDashboardProvider();
  const badge = formContext.watch('badge');
  const entry = formContext.watch('entry');
  const subscription = formContext.watch('subscription');
  const selectedEntry = subscription || entry;
  const previousEntry = usePreviousValue(selectedEntry);

  formContext.register('badge', {
    valueAsNumber: true,
  });

  useEffect(() => {
    const previousRenderedTagInputs = previousEntry?.entry?.people || previousEntry?.people || 0;
    const entryHasChanged = selectedEntry !== previousEntry;
    const inputTagsToReset = entryHasChanged ? previousRenderedTagInputs : 0;
    
    [...Array(inputTagsToReset)]
      .forEach((_, index) => formContext.setValue(`tag${index}`, null));
  }, [selectedEntry, previousEntry, formContext]);
  
  const debouncedBadgeUpdate = useMemo(
    () => debounce((event) => onBadgeUpdate(event.target.value), 300)
  , [onBadgeUpdate]);

  useEffect(() => {
    return () => {
      debouncedBadgeUpdate.cancel();
    }
  }, [debouncedBadgeUpdate]);

  useEffect(() => {
    onSubmitStateUpdate(isSubmitting);
  }, [onSubmitStateUpdate, isSubmitting]);

  return (
    <FormContainer formContext={formContext} onSuccess={formContext.handleSubmit(submitAction)}>
      <Grid container spacing={1} minWidth={500} maxWidth={500}>
        <Grid item md={12}>
          <TextFieldElementPreventEnter
            margin="normal"
            fullWidth
            id="badge"
            label="Legitimatie"
            validation={{ validate: (value) => !value || value > 0 }}
            parseError={() => 'Introduceti o legitimatie valida'}
            name="badge"
            autoComplete="off"
            autoFocus
            onChange={debouncedBadgeUpdate}
          />
        </Grid>
        {badge && !!subscriptions?.length &&
          <Grid item md={12}>
            <AutocompleteElement
              label="Abonament existent"
              name="subscription"
              options={(subscriptions || []).map(subscription => ({ ...subscription, label: `${subscription.entry.name} (-> ${subscription.expiresAt})` }))}
            />
          </Grid>
        }
        {!subscription &&
          <Grid item md={12}>
            <AutocompleteElement
              label="Intrare"
              name="entry"
              options={(entries || []).filter(entry => entry.type === 1).map(entry => ({ ...entry, label: `${entry.name}` }))}
              required
              rules={{
                required: 'Selectati un tip de intrare'
              }}
            />
          </Grid>
        }
        <Grid item md={12}>
          {selectedEntry &&
            [...Array(selectedEntry.entry?.people || selectedEntry.people || 1)].map((_, index) =>
              <TextFieldElementPreventEnter
                margin="normal"
                required={index === 0}
                fullWidth
                key={`tag${index + 1}`}
                id={`tag${index || ''}`}
                validation={{ validate: (value) => !value || value > 0 }}
                label={`Bratara ${index + 1}`}
                name={`tag${index}`}
                autoComplete="off"
                parseError={() => 'Introduceti o bratara valida'}
              />
            )
          }
        </Grid>
        {entry && 
          <Grid item md={12}>
            <Typography fontSize={30} color="error">
              TOTAL: {entry.price} lei
            </Typography>
          </Grid>
        }
        <Grid item md={12}>
          <LoadingButton
            variant='contained'
            fullWidth
            type='submit'
            loading={isSubmitting}
            sx={{ py: 2, mt: '1rem' }}
          >
            {submitText}
          </LoadingButton>
        </Grid>
      </Grid>
    </FormContainer>
  )
}
