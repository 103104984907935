import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Button, Typography, Container, Box } from '@mui/material';
import image404 from '../assets/images/404.svg';

const StyledContent = styled('div')({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  justifyContent: 'center'
});

export const Page404 = () => {
  return (
    <>
      <StyledContent sx={{ textAlign: 'center', alignItems: 'center' }}>
        <Container>
          <Typography variant="h3" paragraph>
            Pagina accesata nu exista!
          </Typography>

          <Box
            component="img"
            src={image404}
            sx={{ height: 260, mx: 'auto', my: { xs: 5, sm: 10 } }}
          />

          <Button to="/" size="large" variant="contained" component={RouterLink}>
            Pagina Principala
          </Button>
        </Container>
      </StyledContent>
    </>
  );
}
