import { TopMenu } from "../../components/TopMenu";
import { Button, Card, Table, TableContainer, TableBody, TableRow, TableCell, Typography } from "@mui/material";
import { useNavigate, useOutlet } from "react-router-dom";
import { useManagerProvider } from "../../hooks/useManagerProvider";
import TableColumns from "../../components/TableColumns";
import AddIcon from '@mui/icons-material/Add';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { useDashboardProvider } from "../../hooks/useDashboardProvider";
import { useConfirmationDialog } from "../../hooks/useConfirmationDialog";
import { usePreloader } from "../../hooks/usePreloader";
import API from "../../api/client";
import { useSnackbar } from "notistack";

const TABLE_HEAD = [
  { id: 'name', label: 'Nume', alignRight: false },
  { id: 'zones', label: 'Zone' },
  { id: 'validity', label: 'Zile valabilitate' },
  { id: 'duration', label: 'Durata minute' },
  { id: 'price', label: 'Pret' },
  { id: 'delete' }
];

export const EntriesPage = () => {
  const { zones } = useManagerProvider();
  const { entries, setEntries } = useDashboardProvider();
  const { showDialog } = useConfirmationDialog();
  const navigate = useNavigate();
  const outlet = useOutlet();
  const { showPreloader } = usePreloader();
  const { enqueueSnackbar } = useSnackbar();
  
  const deleteEntry = (id) => {
    showPreloader(true);

    API.delete(`admin/entries/${id}`)
      .then(() => {
        setEntries(entries.filter(entry => entry.id !== id))
        enqueueSnackbar(`Tipul de intrare a fost sters cu succes`, { variant: 'success' })
      })
      .catch((error) => enqueueSnackbar(`Tipul de intrare nu a putut fi sters: ${error.message}`, { variant: 'error' }))
      .finally(() => showPreloader(false))
  } 

  return (
    <>
      <TopMenu title="Manager Intrari">
        <Button variant="contained" startIcon={<AddIcon />} onClick={() => navigate('/dashboard/manager/entries/new')}>
          Tip Intrare Noua
        </Button>
      </TopMenu>
      <Card sx={{ display: 'flex', flexDirection: 'column', minHeight: 0 }}>
        {zones && entries && 
          <TableContainer sx={{ minWidth: 800, flexGrow: 1 }}>
            <Table stickyHeader>
              <TableColumns
                headLabel={TABLE_HEAD}
                rowCount={entries.length}
              />
              <TableBody>
                {entries.map((entry) => {
                  return (
                    <TableRow hover key={entry.id} tabIndex={-1}>
                      <TableCell component="th" scope="row">
                        <Typography variant="subtitle2" noWrap>
                          {entry.name}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        {entry.zones.map(zone => zone.name).join(', ')}
                      </TableCell>
                      <TableCell>
                        {entry.validity}
                      </TableCell>
                      <TableCell>
                        {entry.duration}
                      </TableCell>
                      <TableCell>
                        {entry.price} lei
                      </TableCell>
                      <TableCell onClick={() => showDialog({
                        title: 'Stergeti tipul de intrare',
                        message: `Confirmati stergerea tipului de intrare - ${entry.name}?`,
                        onConfirm: () => deleteEntry(entry.id)
                      })}>
                        <Typography color="error" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '5px' }}>
                          <RemoveCircleIcon />
                        </Typography>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        }
      </Card>
      {outlet}
    </>
  );
};
