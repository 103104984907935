import { styled, alpha } from '@mui/material/styles';
import { OutlinedInput, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import debounce from 'lodash.debounce';
import { useMemo, useEffect } from 'react';

const StyledRoot = styled('div')(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: 20,
}));

const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': {
    width: 320,
    boxShadow: theme.customShadows.z8,
  },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
  },
}));

export default function SearchToolbar({ onSearchText }) {
  const debouncedChangeHandler = useMemo(
    () => debounce((event) => onSearchText(event.target.value), 300)
  , [onSearchText]);

  useEffect(() => {
    return () => {
      debouncedChangeHandler.cancel();
    }
  }, [debouncedChangeHandler]);

  return (
    <StyledRoot>
      <StyledSearch
        onChange={debouncedChangeHandler}
        onClick={(event) => event.target.select()}
        placeholder=""
        startAdornment={
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        }
      />
    </StyledRoot>
  );
}
