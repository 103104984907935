import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, MenuItem, Select, Stack, TextField } from "@mui/material"
import { useCallback, useState } from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { MobileDateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { format, isValid } from "date-fns";

export const TableFilters = ({ options, defaultValue, onChange }) => {
  const [showDialog, setShowDialog] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [value, setValue] = useState(defaultValue.id);
  const filterOptions = [ ...options, { id: 'choose', label: 'Alta perioada' } ];

  const onFilterChange = useCallback((id) => {
    setValue(id);

    if (id === 'choose') return setShowDialog(true);
    if (id === 'custom') return onChange({ startDate, endDate });
    
    onChange(options.find(option => option.id === id));
  }, [onChange, options, endDate, startDate]);

  const onCustomFilterCancel = useCallback(() => {
    const revertOption = startDate && endDate ? { id: 'custom', startDate, endDate } : options[0]

    setValue(revertOption.id);
    setShowDialog(false);
    onChange(revertOption);
  }, [options, startDate, endDate, onChange]);

  const onCustomFilterSelect = useCallback(() => {
    setValue('custom');
    setShowDialog(false);
    onChange({ startDate, endDate });
  }, [startDate, endDate, onChange]);
  
  return <Stack sx={{ minHeight: '100px', marginRight: '20px' }} justifyContent="center" alignItems="flex-end">
    <FormControl sx={{ width: '300px' }}>
      <Select
        id="table-filter"
        value={value}
        onChange={(event) => onFilterChange(event.target.value)}
      >
        {filterOptions.map(option => <MenuItem key={option.id} value={option.id}>{option.label}</MenuItem>)}
        {!!startDate && !!endDate &&
          <MenuItem key="custom" value="custom">{format(startDate, 'dd/MM/yyyy')} - {format(endDate, 'dd/MM/yyyy')}</MenuItem>
        }
      </Select>
    </FormControl>
    <Dialog
        open={showDialog}
        aria-labelledby="filter-dialog-title"
        aria-describedby="filter-dialog-description"
      >
      <DialogTitle id="filter-dialog-title">
        Selectati perioada
      </DialogTitle>
      <DialogContent>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <MobileDateTimePicker
            label="Data inceput"
            inputFormat="dd/MM/yyyy HH:mm"
            value={startDate}
            maxDate={endDate}
            name="startDate"
            onChange={(date) => isValid(date) && setStartDate(date)}
            renderInput={(params) => <TextField autoComplete="off" fullWidth {...params} inputProps={{...params.inputProps, readOnly: true}} sx={{ marginTop: '16px', marginBottom: '8px' }}/>}
          />
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <MobileDateTimePicker
            label="Data sfarsit"
            inputFormat="dd/MM/yyyy HH:mm"
            value={endDate}
            minDate={startDate}
            name="endDate"
            onChange={(date) => isValid(date) && setEndDate(date)}
            renderInput={(params) => <TextField autoComplete="off" fullWidth {...params} inputProps={{...params.inputProps, readOnly: true}}sx={{ marginTop: '16px', marginBottom: '8px' }}/>}
          />
        </LocalizationProvider>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCustomFilterCancel}>Inchide</Button>
        <Button disabled={!startDate || !endDate} onClick={onCustomFilterSelect} autoFocus>
          Vezi perioada
        </Button>
      </DialogActions>
    </Dialog>
  </Stack>;
}