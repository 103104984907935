import { useNavigate } from "react-router-dom";
import API from '../../../api/client';
import { useSnackbar } from "notistack";
import { useState } from "react";
import { UserForm } from "../../../components/UserForm";
import { AppDialog } from "../../../components/AppDialog";

export const NewUserPage = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const submitUser = (data) => {
    return API.post('admin/users', data)
      .then(() => enqueueSnackbar(`Utilizatorul a fost creat cu succes`, { variant: 'success' }))
      .catch(error => {
        enqueueSnackbar(`Utilizatorul nu a putut fi creat: ${error.message}`, { variant: 'error' });

        throw error;
      });
  }

  return (
    <AppDialog title="Utilizator nou" preventClose={isSubmitting}>
      <UserForm defaultValues={{ roles: ['ROLE_USER'] }} submitAction={(data) => submitUser(data).then(() => navigate(-1))} submitText="Adauga utilizator" onSubmitStateUpdate={setIsSubmitting} />
    </AppDialog>
  );
};
