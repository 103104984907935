import { Navigate, useOutlet, useLocation } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";

export const HomePage = () => {
  const { user } = useAuth();
  const location = useLocation();
  const outlet = useOutlet();

  if (user) {
    return <Navigate to="/dashboard" replace />;
  }

  if (location.pathname === '/') {
    return <Navigate to="/login" replace />;
  }

  return outlet;
};
