import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from './hooks/useAuth';
import App from './App';
import ThemeProvider from './theme';
import { AxiosInterceptor } from './api/client';
import { ConfirmationDialog } from './hooks/useConfirmationDialog';
import { Preloader } from './hooks/usePreloader';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthProvider>
        <AxiosInterceptor>
          <ThemeProvider>
            <Preloader>
              <ConfirmationDialog>
                <App />
              </ConfirmationDialog>
            </Preloader>
          </ThemeProvider>
        </AxiosInterceptor>
      </AuthProvider>
    </BrowserRouter>
  </React.StrictMode>
);
