import { Grid, Typography } from '@mui/material';
import { LoadingButton } from "@mui/lab";
import { useForm } from "react-hook-form";
import { FormContainer, SwitchElement } from 'react-hook-form-mui'
import { useDashboardProvider } from '../hooks/useDashboardProvider';
import { useEffect, useState } from 'react';
import { differenceInMinutes, parse, format } from 'date-fns'

export const EndSessionForm = ({ defaultValues, submitAction, submitText, onSubmitStateUpdate }) => {
  const formContext = useForm({ defaultValues });
  const isSubmitting = formContext.formState.isSubmitting;
  const tagLost = formContext.watch('tagLost');
  const keyLost = formContext.watch('keyLost');
  const [price, setPrice] = useState(0);
  const [overtime, setOvertime] = useState(0);
  const { options } = useDashboardProvider();

  useEffect(() => {
    const tagLostOption = options.find(option => option.type === 2);
    const keyLostOption = options.find(option => option.type === 3);
    const bufferTimeOption = options.find(option => option.type === 5);
    const extraHourTaxOption = options.find(option => option.type === 1);
    const parsedEndsAt = parse(defaultValues.endsAt, 'yyyy-MM-dd HH:mm', new Date());
    const expiryInMins = differenceInMinutes(parsedEndsAt, new Date()) + (Number(bufferTimeOption?.value) || 0);
    const overtime = Math.abs(Math.min(0, expiryInMins));

    let total = 0;

    if (tagLost) total += Number(tagLostOption?.value) || 0;
    if (keyLost) total += Number(keyLostOption?.value) || 0;
    if (overtime) total += Math.ceil(overtime/60) * (Number(extraHourTaxOption?.value)|| 0);

    setOvertime(overtime);
    setPrice(total);
  }, [tagLost, keyLost, defaultValues, options])

  useEffect(() => {
    onSubmitStateUpdate(isSubmitting);
  }, [onSubmitStateUpdate, isSubmitting]);

  return (
    <FormContainer formContext={formContext} onSuccess={formContext.handleSubmit(data => submitAction({ ...data, overtime, price }))}>
      <Grid container spacing={1} minWidth={500} maxWidth={500}>
        {!!defaultValues?.client &&
          <Grid item md={12}>
            <strong>Client:</strong> {defaultValues.client.name}
          </Grid>
        }
        <Grid item md={12}>
          <strong>Bratara:</strong> {defaultValues.tag}
        </Grid>
        <Grid item md={12}>
          <strong>Ora intrare:</strong> {format(parse(defaultValues.startedAt, 'yyyy-MM-dd HH:mm', new Date()), 'HH:mm (dd/MM/yyyy)')}
        </Grid>
        <Grid item md={12}>
          <strong>Ora iesire:</strong> {format(parse(defaultValues.endsAt, 'yyyy-MM-dd HH:mm', new Date()), 'HH:mm (dd/MM/yyyy)')}
        </Grid>
        <Grid item md={12}>
          <SwitchElement
            label="Bratara pierduta"
            name='tagLost'
          />
        </Grid>
        <Grid item md={12}>
          <SwitchElement
            label="Cheie pierduta"
            name='keyLost'
          />
        </Grid>
        {!!overtime && 
          <Grid item md={12}>
            <Grid item md={12}>
              <Typography fontSize={30} color="error">
                Depasire: {overtime} minute ({Math.ceil(overtime/60)} ore)
              </Typography>
            </Grid>
          </Grid>
        }
        <Grid item md={12}>
          <Grid item md={12}>
            <Typography fontSize={30} color="error">
              TOTAL: {price} lei
            </Typography>
          </Grid>
        </Grid>
        <Grid item md={12}>
          <LoadingButton
            variant='contained'
            fullWidth
            type='submit'
            loading={isSubmitting}
            sx={{ py: 2, mt: '1rem' }}
          >
            {submitText}
          </LoadingButton>
        </Grid>
      </Grid>
    </FormContainer>
  )
}
