import { Grid } from "@mui/material";
import { useForm } from "react-hook-form";
import { FormContainer, TextFieldElement } from "react-hook-form-mui";
import { LoadingButton } from "@mui/lab";
import { useEffect } from "react";

export const ZoneForm = ({ defaultValues, submitAction, submitText, onSubmitStateUpdate }) => {
  const formContext = useForm({ defaultValues });
  const isSubmitting = formContext.formState.isSubmitting;

  useEffect(() => {
    onSubmitStateUpdate(isSubmitting);
  }, [onSubmitStateUpdate, isSubmitting]);

  return (
    <FormContainer formContext={formContext} onSuccess={formContext.handleSubmit(submitAction)}>
      <Grid container spacing={1} minWidth={500} maxWidth={500}>
        <Grid item md={12}>
          <TextFieldElement
            margin="normal"
            fullWidth
            id="name"
            label="Nume"
            name="name"
            autoComplete="off"
            required
            autoFocus
            parseError={() => 'Introduceti un nume'}
          />
        </Grid>
        <Grid item md={12}>
          <TextFieldElement
            margin="normal"
            fullWidth
            id="accessGroup"
            label="Grup Acces"
            name="accessGroup"
            autoComplete="off"
            required
            validation={{ validate: (value) => !value || value > 0 }}
            parseError={() => 'Introduceti grup acces'}
          />
        </Grid>
        <Grid item md={12}>
          <LoadingButton
            variant='contained'
            fullWidth
            type='submit'
            loading={isSubmitting}
            sx={{ py: 2, mt: '1rem' }}
          >
            {submitText}
          </LoadingButton>
        </Grid>
      </Grid>
    </FormContainer>
  );
};
