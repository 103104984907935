import { TextFieldElement } from "react-hook-form-mui"

export const TextFieldElementPreventEnter = ({ ...props }) => {
  return <TextFieldElement
    onKeyDown={(event) => {
      if (event.code === 'Enter') event.preventDefault();
    }} 
    onClick={(event) => event.target?.select()}
    {...props}
  />
}
