import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { useSnackbar } from "notistack";
import API from "../../../api/client";
import { ZoneForm } from "../../../components/ZoneForm";
import { useManagerProvider } from "../../../hooks/useManagerProvider";
import { AppDialog } from "../../../components/AppDialog";
import { usePreloader } from "../../../hooks/usePreloader";
import { useEffect } from "react";

export const EditZonePage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { zones, setZones } = useManagerProvider();
  const [zone, setZone] = useState();
  const { showPreloader } = usePreloader();

  useEffect(() => {
    showPreloader(true);

    API.get(`admin/zones/${id}`)
      .then(response => setZone(response.data))
      .catch(error => {
        enqueueSnackbar(`Utilizatorul nu exista sau nu a putut fi incarcat: ${error.message}`, { variant: 'error' });

        throw error;
      })
      .finally(() => showPreloader(false))
  }, [id, enqueueSnackbar, showPreloader]);

  const submitZone = (data) => {
    return API.put(`admin/zones/${id}`, { ...data, accessGroup: Number(data.accessGroup) })
      .then(response => {
        setZones(zones.map(zone => Number(zone.id) === Number(id) ? response.data : zone));
        enqueueSnackbar(`Zona a fost modificata cu succes`, { variant: 'success' });
      })
      .catch(error => {
        enqueueSnackbar(`Zona nu a putut fi modificata: ${error.message}`, { variant: 'error' });

        throw error;
      });
  }

  return (
    <AppDialog title="Modificare zona" preventClose={isSubmitting}>
      {zone &&
        <ZoneForm defaultValues={zone} submitAction={(data) => submitZone(data).then(() => navigate(-1))} submitText="Modifica zona" onSubmitStateUpdate={setIsSubmitting} />
      }
    </AppDialog>
  );
};
