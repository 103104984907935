const serialize = (entry) => {
  return { 
    ...entry,
    zones: entry.zones.map(zone => zone.id || zone),
    allowedSessionNo: Number(entry.allowedSessionNo),
    price: Number(entry.price),
    validity: Number(entry.validity),
    duration: Number(entry.duration),
    people: Number(entry.people)
  };
};

const mapZonesToEntry = (entry, zones) => {
  const mappedZones = entry.zones.map(zoneId => zones.find(zone => zone.id === zoneId));

  return { ...entry, zones: mappedZones };
}

const normalize = (payload, zones = []) => {
  if (Array.isArray(payload)) {
    return payload.map(entry => mapZonesToEntry(entry, zones))
  }

  return mapZonesToEntry(payload, zones);
}

export { serialize, normalize };
