import { useSnackbar } from "notistack";
import { createContext, useContext, useEffect, useState } from "react";
import API from '../api/client';

const ManagerContext = createContext();

export const ManagerProvider = ({ children }) => {
  const [zones, setZones] = useState();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    API.get('admin/zones', { retry: 3, retryDelay: 2000 })
      .then(response => {
        setZones(response.data);
      })
      .catch(error => enqueueSnackbar(`Zonele de acces nu au putut fi incarcate: ${error.message}`, { variant: 'error' }));
  }, [enqueueSnackbar]);

  return <ManagerContext.Provider value={{ zones, setZones }}>{children}</ManagerContext.Provider>;
};

export const useManagerProvider = () => {
  return useContext(ManagerContext);
};
