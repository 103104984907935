import { Routes, Route } from "react-router-dom";
import { LoginPage } from "./pages/Login";
import { DashboardPage } from "./pages/Dashboard";
import { HomePage } from "./pages/Home";
import { NewSessionPage } from "./pages/Dashboard/Sessions/NewSession";
import { SessionsPage } from "./pages/Dashboard/Sessions";
import { NewClientPage } from "./pages/Dashboard/Sessions/NewClient";
import { Page404 } from "./pages/Page404";
import { ManagerPage } from "./pages/Manager";
import { UsersPage } from "./pages/Manager/Users";
import { EntriesPage } from "./pages/Manager/Entries";
import { NewUserPage } from "./pages/Manager/Users/NewUser";
import { NewEntryPage } from "./pages/Manager/Entries/NewEntry";
import { EditUserPage } from "./pages/Manager/Users/EditUser";
import { ZonesPage } from "./pages/Manager/Zones";
import { NewZonePage } from "./pages/Manager/Zones/NewZone";
import { NewSubscriptionPage } from "./pages/Dashboard/Sessions/NewSubscription";
import { OptionsPage } from "./pages/Manager/Options";
import { EditOptionPage } from "./pages/Manager/Options/EditOption";
import { EndSessionPage } from "./pages/Dashboard/Sessions/EndSession";
import { PaymentsPage } from "./pages/Manager/Payments";
import { EditZonePage } from "./pages/Manager/Zones/EditZone";

export default function App() {
  return (
    <Routes>
      <Route path="" element={<HomePage />}>
        <Route path="login" element={<LoginPage />} />
      </Route>

      <Route path="/dashboard" element={<DashboardPage />}> 
        <Route path="sessions" element={<SessionsPage />}>
          <Route path="new" element={<NewSessionPage />} />
          <Route path=":id" element={<EndSessionPage />} />
          <Route path="client" element={<NewClientPage />} />
          <Route path="subscription" element={<NewSubscriptionPage />} />
        </Route>

        <Route path="manager" element={<ManagerPage />} >
          <Route path="users" element={<UsersPage />}>
            <Route path="new" element={<NewUserPage />} />
            <Route path=":id" element={<EditUserPage />} />
          </Route>
          <Route path="entries" element={<EntriesPage />}>
            <Route path="new" element={<NewEntryPage />} />
          </Route>
          <Route path="zones" element={<ZonesPage />}>
            <Route path="new" element={<NewZonePage />} />
            <Route path=":id" element={<EditZonePage />} />
          </Route>
          <Route path="options" element={<OptionsPage />}>
            <Route path=":id" element={<EditOptionPage />} />
          </Route>
          <Route path="payments" element={<PaymentsPage />}/>
        </Route>
      </Route>
      <Route path="*" element={<Page404 />} />
    </Routes>
  );
}
