import { Grid } from "@mui/material";
import { useForm } from "react-hook-form";
import { FormContainer, TextFieldElement, AutocompleteElement, SelectElement, SwitchElement } from "react-hook-form-mui";
import { LoadingButton } from "@mui/lab";
import { useEffect } from "react";

export const EntryForm = ({ defaultValues, submitAction, submitText, zones, onSubmitStateUpdate }) => {
  const formContext = useForm({ defaultValues: { unlimitedEntries: true, allDay: true, validity: 1, isChild: false, isValidForWeekend: false, isValidForWeekday: false, ...defaultValues } });
  const isSubmitting = formContext.formState.isSubmitting;
  const unlimitedEntries = formContext.watch('unlimitedEntries');
  const allDay = formContext.watch('allDay');
  const type = formContext.watch('type');
  const updateDefaultValidity = (type) => {
    const validities = {
      1: 1,
      2: 30
    }

    formContext.setValue('validity', validities[type]);
  }

  useEffect(() => {
    onSubmitStateUpdate(isSubmitting);
  }, [onSubmitStateUpdate, isSubmitting]);

  return (
    <FormContainer
      formContext={formContext}
      onSuccess={formContext.handleSubmit((data) => {
        submitAction({ 
          ...data,
          allowedSessionNo: type === 1 ? 1 : unlimitedEntries ? 9999 : data.allowedSessionNo,
          duration: allDay ? 1200 : data.duration
        })
      })}
    >
      <Grid container spacing={1} minWidth={500} maxWidth={500}>
        <Grid item md={12}>
          <TextFieldElement
            margin="normal"
            fullWidth
            id="name"
            label="Nume"
            name="name"
            autoComplete="off"
            required
            autoFocus
            parseError={() => 'Introduceti un nume'}
          />
        </Grid>
        <Grid item md={12}>
          <AutocompleteElement
            multiple
            label="Zone Acces"
            labelKey="name"
            name="zones"
            options={(zones || []).map(zone => ({ ...zone, label: zone.name }))}
            required
            rules={{
              required: 'Selectati cel putin o zona de acces'
            }}
            textFieldProps={{
              sx: { marginBottom: '8px' }
            }}
          />
        </Grid>
        <Grid item md={12}>
         <SelectElement
            label="Tip Intrare"
            fullWidth
            name="type"
            options={[
              { id: 1, label: 'Intrare' },
              { id: 2, label: 'Abonament' }
            ]}
            required
            parseError={() => 'Selectati tipul intrarii'}
            onChange={updateDefaultValidity}
          />
        </Grid>
        {type === 2 && 
          <Grid item md={12}>
            <SwitchElement
              label="Intrari nelimitate"
              name='unlimitedEntries'
            />
            {!unlimitedEntries && 
              <TextFieldElement
                margin="normal"
                fullWidth
                id="allowedSessionNo"
                label="Numar intrari incluse"
                name="allowedSessionNo"
                autoComplete="off"
                required
                validation={{ validate: (value) => !value || value > 0 }}
                parseError={() => 'Introduceti numar intrari incluse'}
              />
            }
          </Grid>
        }
        <Grid item md={12}>
          <SwitchElement
            label="Toata ziua"
            name='allDay'
          />
          {!allDay &&
            <TextFieldElement
              margin="normal"
              fullWidth
              id="duration"
              label="Durata intrare minute"
              name="duration"
              autoComplete="off"
              required
              validation={{ validate: (value) => !value || value > 0 }}
              parseError={() => 'Introduceti durata in minute'}
            />
          }
        </Grid>
        <Grid item md={12}>
          <TextFieldElement
            margin="normal"
            fullWidth
            id="price"
            label="Pret"
            name="price"
            autoComplete="off"
            required
            validation={{ validate: (value) => !value || value > 0 }}
            parseError={() => 'Introduceti pretul'}
          />
        </Grid>
        <Grid item md={12}>
          <TextFieldElement
            margin="normal"
            fullWidth
            id="validity"
            label="Zile valabilitate"
            name="validity"
            autoComplete="off"
            required
            validation={{ validate: (value) => !value || value > 0 }}
            disabled={!type || type === 1}
            parseError={() => 'Introduceti zile de valabilitate'}
          />
        </Grid>
        <Grid item md={12}>
          <TextFieldElement
            margin="normal"
            fullWidth
            id="people"
            label="Numar persoane incluse"
            name="people"
            autoComplete="off"
            required
            validation={{ validate: (value) => !value || value > 0 }}
            parseError={() => 'Introduceti numar persoane incluse pe intrare'}
          />
        </Grid>
        <Grid item md={12}>
          <LoadingButton
            variant='contained'
            fullWidth
            type='submit'
            loading={isSubmitting}
            sx={{ py: 2, mt: '1rem' }}
          >
            {submitText}
          </LoadingButton>
        </Grid>
      </Grid>
    </FormContainer>
  );
};
