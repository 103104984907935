import axios from 'axios';
import { useAuth } from "../hooks/useAuth";
import { useLayoutEffect } from 'react';
import { ConcurrencyManager } from 'axios-concurrency';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL || 'https://api.waterparkcraiova.bluelogic.ro/v1'
});
ConcurrencyManager(axiosInstance, 1);
const AxiosInterceptor = ({ children }) => {
  const { user, logout } = useAuth();

  useLayoutEffect(() => {
    const requestHandler = (config) => {
      config.headers['X-AUTH-TOKEN'] = user ? user.userIdentifier : null;
      
      return config;
    };

    const errorHandler = (error) => {
      if (error.response?.status === 401) logout();

      return Promise.reject(error);
    };

    const retryHandler = (error) => {
      const { config } = error;

      if (!config || !config.retry) {
        return Promise.reject(error);
      }

      if (error.response?.status !== 500) {
        return Promise.reject(error);
      }

      config.retry -= 1;
      config.headers = null;

      const delayRetryRequest = new Promise((resolve) => {
        setTimeout(() => {
          resolve();
        }, config.retryDelay || 1000);
      });

      return delayRetryRequest.then(() => axiosInstance(config));
    };

    const requestInterceptor = axiosInstance.interceptors.request.use(requestHandler);
    const retryInterceptor = axiosInstance.interceptors.response.use(undefined, retryHandler);
    const responseInterceptor = axiosInstance.interceptors.response.use(undefined, errorHandler);

    return () => {
      axiosInstance.interceptors.response.eject(retryInterceptor);
      axiosInstance.interceptors.response.eject(responseInterceptor);
      axiosInstance.interceptors.request.eject(requestInterceptor);
    };
  }, [user, logout]);

  return children;
}

export default axiosInstance;
export { AxiosInterceptor };