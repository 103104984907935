import { useSnackbar } from "notistack";
import { createContext, useContext, useEffect, useState } from "react";
import API from '../api/client';

const DashboardContext = createContext();

export const DashboardProvider = ({ children }) => {
  const [entries, setEntries] = useState();
  const [options, setOptions] = useState();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    API.get('entries', { retry: 3, retryDelay: 2000 })
      .then(response => {
        setEntries(response.data);
      })
      .catch(error => enqueueSnackbar(`Tipurile de intrari nu au putut fi incarcate: ${error.message}`, { variant: 'error' }));
    
    API.get('options', { retry: 3, retryDelay: 1500 })
      .then(response => {
        setOptions(response.data);
      })
      .catch(error => enqueueSnackbar(`Optiunile nu au putut fi incarcate: ${error.message}`, { variant: 'error' }));
  }, [enqueueSnackbar]);

  return <DashboardContext.Provider value={{ entries, setEntries, options, setOptions }}>{children}</DashboardContext.Provider>;
};

export const useDashboardProvider = () => {
  return useContext(DashboardContext);
};
