import { Grid, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { AutocompleteElement, FormContainer, TextFieldElement } from "react-hook-form-mui";
import { LoadingButton } from "@mui/lab";
import { useEffect, useMemo, useState } from "react";
import { useDashboardProvider } from "../hooks/useDashboardProvider";
import debounce from 'lodash.debounce';
import { MobileDateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { TextFieldElementPreventEnter } from "./TextFieldElementPreventEnter";
import { format } from "date-fns";

export const SubscriptionForm = ({ defaultValues, submitAction, submitText, onSubmitStateUpdate, onBadgeUpdate }) => {
  const formContext = useForm({ defaultValues });
  const [startDate, setStartDate] = useState(defaultValues?.startsAt || new Date());
  const entry = formContext.watch('entry');
  const { entries } = useDashboardProvider();
  const isSubmitting = formContext.formState.isSubmitting;
  const debouncedBadgeUpdate = useMemo(
    () => debounce((event) => onBadgeUpdate(event.target.value), 300)
  , [onBadgeUpdate]);

  useEffect(() => {
    return () => {
      debouncedBadgeUpdate.cancel();
    }
  }, [debouncedBadgeUpdate]);

  useEffect(() => {
    onSubmitStateUpdate(isSubmitting);
  }, [onSubmitStateUpdate, isSubmitting]);

  return (
    <FormContainer formContext={formContext} onSuccess={formContext.handleSubmit((data) => submitAction({ ...data, startsAt: format(startDate, 'yyyy-MM-dd HH:mm') }))}>
      <Grid container spacing={1} minWidth={500} maxWidth={500}>
        <Grid item md={12}>
          <TextFieldElementPreventEnter
            margin="normal"
            fullWidth
            id="badge"
            label="Legitimatie"
            name="badge"
            autoComplete="off"
            required
            autoFocus
            disabled={!!defaultValues?.badge}
            onChange={debouncedBadgeUpdate}
            validation={{ validate: (value) => !value || value > 0 }}
            parseError={() => 'Introduceti o legitimatie'}
          />
        </Grid>
        <Grid item md={12}>
          <AutocompleteElement
            label="Abonament"
            name="entry"
            options={(entries || []).filter(entry => entry.type === 2).map(entry => ({ ...entry, label: entry.name }))}
            required
            rules={{
              required: 'Selectati un abonament'
            }}
            textFieldProps={{
              sx: { marginBottom: '8px' }
            }}
          />
        </Grid>
        <Grid item md={12}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <MobileDateTimePicker
              label="Data incepere abonament"
              inputFormat="dd/MM/yyyy"
              value={startDate}
              name="startDate"
              onChange={setStartDate}
              renderInput={(params) => <TextFieldElement autoComplete="off" name="birthdate" value={startDate} fullWidth {...params} inputProps={{...params.inputProps, readOnly: true}} sx={{ marginTop: '16px', marginBottom: '8px' }}/>}
            />
          </LocalizationProvider>
        </Grid>
        {entry && 
          <Grid item md={12}>
            <Typography fontSize={30} color="error">
              TOTAL: {entry.price} lei
            </Typography>
          </Grid>
        }
        <Grid item md={12}>
          <LoadingButton
            variant='contained'
            fullWidth
            type='submit'
            loading={isSubmitting}
            sx={{ py: 2, mt: '1rem' }}
          >
            {submitText}
          </LoadingButton>
        </Grid>
      </Grid>
    </FormContainer>
  );
};
