import { useNavigate } from "react-router-dom";
import { useSnackbar } from 'notistack';
import { useState, useCallback } from "react";
import API from '../../../api/client';
import { SessionForm } from "../../../components/SessionForm";
import { AppDialog } from "../../../components/AppDialog";
import { usePreloader } from "../../../hooks/usePreloader";
import { serialize } from "../../../serializers/Session";

export const NewSessionPage = () => {
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [subscriptions, setSubscriptions] = useState([]);
  const { showPreloader } = usePreloader();
  const { enqueueSnackbar } = useSnackbar();

  const submitSession = useCallback((data) => {
    return API.post('sessions', serialize(data))
      .then(() => enqueueSnackbar(`Intrarea a fost creata cu succes`, { variant: 'success' }))
      .catch((error) => {
        enqueueSnackbar(`Intrarea nu a putut fi creata: ${error.message}`, { variant: 'error' });

        throw error;
      });
  }, [enqueueSnackbar]);
  
  const fetchSubscriptionsByBadge = useCallback((badge) => {
    if (!badge || badge === '') {
      setSubscriptions([]);

      return;
    }

    showPreloader(true);

    return API.get('subscriptions', { params: { 'client.badge': badge } })
      .then((response) => setSubscriptions(response.data))
      .catch((error) => {
        enqueueSnackbar(`Abonamentele clientului nu au putut fi incarcate: ${error.message}`, { variant: 'error' });
      })
      .finally(() => showPreloader(false));
  }, [enqueueSnackbar, showPreloader]);

  return (
    <AppDialog title="Intrare noua" preventClose={isSubmitting}>
      <SessionForm subscriptions={subscriptions} submitAction={(data) => submitSession(data).then(() => navigate(-1))} submitText="Adauga intrare" onSubmitStateUpdate={setIsSubmitting} onBadgeUpdate={fetchSubscriptionsByBadge} />
    </AppDialog>
  )
}
