import { useState } from 'react';
import { styled } from '@mui/material/styles';
import { Stack, IconButton, InputAdornment, Typography } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { LoadingButton } from '@mui/lab';
import { useAuth } from "../hooks/useAuth";
import { useForm } from "react-hook-form";
import { FormContainer, TextFieldElement } from 'react-hook-form-mui';
import { useSnackbar } from 'notistack';
import API from '../api/client';

const StyledContent = styled('div')({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  justifyContent: 'center',
  margin: 'auto',
});

export const LoginPage = () => {
  const { login } = useAuth();
  const [showPassword, setShowPassword] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const formContext = useForm();
  const isSubmitting = formContext.formState.isSubmitting;
  const submitLogin = (data) => {
    return API.post('auth/login', data)
      .then((response) => login(response.data))
      .catch(error => {
        if (error.response?.status === 401) {
          return enqueueSnackbar('Emailul sau parola sunt incorecte', { variant: 'error' });
        }

        enqueueSnackbar(`Nu se poate realiza autentificarea: ${error.message}`, { variant: 'error' });
      })
  };

  return (
    <>
      <StyledContent sx={{ textAlign: 'center', alignItems: 'center' }}>
        <FormContainer formContext={formContext} onSuccess={formContext.handleSubmit(submitLogin)}>
          <Stack spacing={2} sx={{ width: 500 }}>
            <Typography variant="h4" gutterBottom>
              Autentificare
            </Typography>

            <TextFieldElement
              margin="normal"
              required
              fullWidth
              id="username"
              label="Nume utilizator"
              name="username"
              autoFocus
              autoComplete="off"
              parseError={() => 'Introduceti nume utilizator'}
            />

            <TextFieldElement
              margin="normal"
              required
              fullWidth
              name="password"
              label="Parola"
              type={showPassword ? "text": "password"}
              id="password"
              autoComplete="off"
              parseError={() => 'Introduceti parola'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                      {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <LoadingButton
              variant='contained'
              fullWidth
              type='submit'
              loading={isSubmitting}
              sx={{ py: 2, mt: '1rem' }}
            >
              Intrare
            </LoadingButton>
          </Stack>
        </FormContainer>
      </StyledContent>
    </>
  );
};
