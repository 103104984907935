import { useNavigate } from "react-router-dom";
import { EntryForm } from "../../../components/EntryForm";
import { useState } from "react";
import { useSnackbar } from "notistack";
import API from "../../../api/client";
import { serialize } from "../../../serializers/Entry";
import { useManagerProvider } from "../../../hooks/useManagerProvider";
import { AppDialog } from "../../../components/AppDialog";
import { useDashboardProvider } from "../../../hooks/useDashboardProvider";

export const NewEntryPage = () => {
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { zones } = useManagerProvider();
  const { entries, setEntries } = useDashboardProvider();

  const submitEntry = (data) => {
    return API.post('admin/entries', serialize(data))
      .then(response => {
        setEntries([ ...entries, response.data ]);
        enqueueSnackbar(`Tipul de intrare a fost creat cu succes`, { variant: 'success' });
      })
      .catch(error => {
        enqueueSnackbar(`Tipul de intrare nu a putut fi creat: ${error.message}`, { variant: 'error' });

        throw error;
      });
  }

  return (
    <AppDialog title="Tip intrare noua" preventClose={isSubmitting}>
      <EntryForm defaultValues={{ people: 1 }} zones={zones} submitAction={(data) => submitEntry(data).then(() => navigate(-1))} submitText="Adauga tip intrare" onSubmitStateUpdate={setIsSubmitting} />
    </AppDialog>
  );
};
