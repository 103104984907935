import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { createContext, useContext, useState } from "react";

const DialogContext = createContext();

export const ConfirmationDialog = ({ children }) => {
  const [dialog, showDialog] = useState();
  const confirmDialog = () => {
    dialog?.onConfirm();
    showDialog(null);
  }

  return <DialogContext.Provider value={{ showDialog }}>
      {children}
      {dialog && 
        <>
          <Dialog
            open={true}
          >
            <DialogTitle>{dialog?.title}</DialogTitle>
            <DialogContent>
              {dialog?.message}
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={() => showDialog(null)}>
                Anuleaza
              </Button>
              <Button onClick={confirmDialog}>Confirma</Button>
            </DialogActions>
          </Dialog>
        </>
      }
    </DialogContext.Provider>;
};

export const useConfirmationDialog = () => {
  return useContext(DialogContext);
};
