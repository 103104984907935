import { useLocation, useNavigate } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { useSnackbar } from 'notistack';
import { AppDialog } from "../../../components/AppDialog";
import { SubscriptionForm } from "../../../components/SubscriptionForm";
import API from "../../../api/client";
import { Typography } from "@mui/material";
import { usePreloader } from "../../../hooks/usePreloader";

export const NewSubscriptionPage = () => {
  const { state } = useLocation();
  const badge = state?.badge || null;
  const [client, setClient] = useState(null);
  const { showPreloader } = usePreloader();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const submitSubscription = useCallback((data) => {
    if (!client) {
      enqueueSnackbar(`Nu a fost gasit client pentru legitimatia introdusa`, { variant: 'error' });

      throw Error('Nu a fost gasit clientul pentru legitimatia introdusa')
    }

    return API.post('subscriptions', { ...data, client: client?.id, entry: data.entry?.id })
      .then(() => enqueueSnackbar(`Abonamentul a fost creat cu succes`, { variant: 'success' }))
      .catch(error => {
        enqueueSnackbar(`Abonamentul nu a putut fi creat: ${error.message}`, { variant: 'error' });

        throw error;
      });
  }, [enqueueSnackbar, client]);
  const fetchClientByBadge = useCallback((badge) => {
    if (!badge || badge === '') {
      setClient(null);

      return;
    }

    showPreloader(true);

    return API.get('clients', { params: { badge } })
      .then((response) => {
        if (response.data.length) {
          return setClient(response.data[0])
        }

        navigate('/dashboard/sessions/client', { state: { badge }, replace: true });
      })
      .catch((error) => {
        enqueueSnackbar(`Nu au putut fi incarcati clientii pentru legitimatia introdusa: ${error.message}`, { variant: 'error' });
      })
      .finally(() => showPreloader(false));
  }, [enqueueSnackbar, navigate, showPreloader]);
  
  useEffect(() => {
    fetchClientByBadge(badge);
  }, [fetchClientByBadge, badge])

  return (
    <AppDialog title="Abonament nou" preventClose={isSubmitting}>
      {client && 
        <Typography sx={{ paddingBottom: '17px' }}>
          Client: {client.name}
        </Typography>
      }
      <SubscriptionForm defaultValues={{ badge }} submitAction={(data) => submitSubscription(data).then(() => navigate(-1))} submitText="Adauga abonament" onSubmitStateUpdate={setIsSubmitting} onBadgeUpdate={fetchClientByBadge} />
    </AppDialog>
  )
}
