import { Backdrop, CircularProgress } from "@mui/material";
import { createContext, useContext, useState } from "react";

const PreloaderContext = createContext();

export const Preloader = ({ children }) => {
  const [preloader, showPreloader] = useState(false);

  return <PreloaderContext.Provider value={{ preloader, showPreloader }}>
    {children}
    <Backdrop open={preloader} className="preloader">
      <CircularProgress />
    </Backdrop>
  </PreloaderContext.Provider>;
};

export const usePreloader = () => {
  return useContext(PreloaderContext);
};
