import { Navigate, useLocation, useNavigate, useOutlet } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { Avatar, Button, Container, Menu, MenuItem, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useState } from "react";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import HomeIcon from '@mui/icons-material/Home';
import { DashboardProvider } from "../hooks/useDashboardProvider";

const StyledContent = styled('div')({
  display: 'flex',
  height: '100vh',
  width: '100vw',
  paddingTop: 30,
  paddingBottom: 30,
});

export const DashboardPage = () => {
  const outlet = useOutlet();
  const navigate = useNavigate();
  const location = useLocation();
  const { user, logout } = useAuth();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  if (!user) {
    return <Navigate to="/login" />;
  }

  if (location.pathname === '/dashboard') {
    return <Navigate to="/dashboard/sessions" replace />;
  }

  return (
    <>
      <StyledContent>
        <Container sx={{ display: 'flex', flexDirection: 'column' }}>
          <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ minHeight: '90px' }}>
            <Button onClick={() => navigate('/dashboard/sessions')}>
              <HomeIcon fontSize="large"/>
            </Button>
            <Stack direction="row" alignItems="center">
              {user.username}
              <Avatar
                sx={{ marginLeft: '10px' }}
                aria-controls={open ? 'profile-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
              />
            </Stack>
          </Stack>
          <Menu
            anchorEl={anchorEl}
            id="profile-menu"
            open={open}
            onClose={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                '& .MuiAvatar-root': {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <MenuItem onClick={logout} sx={{ padding: '15px' }}>
              <ExitToAppIcon sx={{ marginRight: '10px' }} /> Deconectare
            </MenuItem>
          </Menu>
            <DashboardProvider>
              {outlet}
            </DashboardProvider>
        </Container>
      </StyledContent>
    </>
  );
};
