import { TopMenu } from "../../components/TopMenu";
import { Card, Table, TableContainer, TableBody, TableRow, TableCell, Typography, Button } from "@mui/material";
import TableColumns from "../../components/TableColumns";
import { useCallback, useEffect, useState } from "react";
import { sub, format } from "date-fns";
import API from "../../api/client";
import { useSnackbar } from "notistack";
import ENTITIES from "../../constants/entities";
import { TableFilters } from "../../components/TableFilters";
import { saveAsExcel } from "../../utils/generateExcel";
import DownloadIcon from '@mui/icons-material/Download';

const TABLE_HEAD = [
  { id: 'receipt', label: 'Incasare', alignRight: false },
  { id: 'value', label: 'Valoare' },
  { id: 'createdAt', label: 'Data' },
];

const FILTERS = [
  { id: 1, startDate: sub(new Date(), { days: 1 }), label: 'Ultima zi' },
  { id: 2, startDate: sub(new Date(), { weeks: 1 }), label: 'Ultima saptamana' },
  { id: 3, startDate: sub(new Date(), { weeks: 2 }), label: 'Ultimele 2 saptamani' },
  { id: 4, startDate: sub(new Date(), { months: 1 }), label: 'Ultima luna' },
  { id: 5, startDate: sub(new Date(), { months: 2 }), label: 'Ultimele 2 luni' }
];

export const PaymentsPage = () => {
  const [startDate, setStartDate] = useState(FILTERS[0].startDate);
  const [endDate, setEndDate] = useState(new Date());
  const [payments, setPayments] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const onFilterChange = useCallback((option) => {
    setStartDate(option.startDate);
    setEndDate(option.endDate || new Date());
  }, []);

  useEffect(() => {
    const controller = new AbortController();

    API.get('payments', { signal: controller.signal, params: { startDate: format(startDate, 'yyyy-MM-dd HH:mm'), endDate: format(endDate, 'yyyy-MM-dd HH:mm') } })
      .then(response => setPayments(response.data))
      .catch(error =>{
        if (error.name === 'CanceledError') return;
        enqueueSnackbar(`Platile nu au putut fi incarcate: ${error.message}`, { variant: 'error' });
      })

    return () => {
      controller.abort();
    };
  }, [startDate, endDate, enqueueSnackbar]);

  let total = 0;
  
  return (
    <>
      <TopMenu title="Raport incasari">
        <Button startIcon={<DownloadIcon />} variant="contained" onClick={() => saveAsExcel(payments.map(payment => ({ 'Incasare': ENTITIES[payment.entityType], 'Valoare': `${payment.price} lei`, 'Data': payment.paymentDate })), 'raport.xlsx')}>
          Exporta Excel
        </Button>
      </TopMenu>
      <Card sx={{ display: 'flex', flexDirection: 'column', minHeight: 0 }}>
        <TableFilters defaultValue={FILTERS[0]} onChange={onFilterChange} options={FILTERS} />
        <TableContainer sx={{ minWidth: 800, flexGrow: 1 }}>
          <Table stickyHeader>
            <TableColumns
              headLabel={TABLE_HEAD}
            />
            <TableBody>
              {payments.map((payment) => {
                
                total += Number(payment.price);

                return (
                  <TableRow hover key={payment.id} tabIndex={-1}>
                    <TableCell component="th" scope="row">
                      <Typography variant="subtitle2" noWrap>
                        {ENTITIES[payment.entityType]}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">{payment.price} lei</TableCell>
                    <TableCell align="left">{payment.paymentDate}</TableCell>
                  </TableRow>
                );
              })}
              <TableRow tabIndex={-1} sx={{ position: 'sticky', bottom: 0, background: 'white', boxShadow: 6 }}>
                <TableCell></TableCell>
                <TableCell align="left"><strong>TOTAL: </strong>{total} lei</TableCell>
                <TableCell align="left"></TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </>
  );
};
