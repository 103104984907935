import { Dialog, DialogTitle, DialogContent, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';

export const AppDialog = ({ children, title, preventClose }) => {
  const navigate = useNavigate();

  return (
    <Dialog open={true} maxWidth="md">
      <DialogTitle sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
        {title}
        <Button sx={{ padding: '10px' }} onClick={() => !preventClose && navigate(-1)}>
          <CloseIcon />
        </Button>
      </DialogTitle>
      <DialogContent>
        {children}
      </DialogContent>
    </Dialog>
  );
};
