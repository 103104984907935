import { useNavigate, useLocation } from "react-router-dom";
import { useCallback, useState } from "react";
import { useSnackbar } from 'notistack';
import { ClientForm } from "../../../components/ClientForm";
import { AppDialog } from "../../../components/AppDialog";
import API from "../../../api/client";

export const NewClientPage = () => {
  const { state } = useLocation();
  const badge = state?.badge || null;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const submitClient = useCallback((data) => {
    return API.post('clients', data)
      .then(() => enqueueSnackbar(`Clientul a fost creat cu succes`, { variant: 'success' }))
      .catch(error => {
        enqueueSnackbar(`Clientul nu a putut fi creat: ${error.message}`, { variant: 'error' });

        throw error;
      });
  }, [enqueueSnackbar]);

  return (
    <AppDialog title="Client nou" preventClose={isSubmitting}>
      <ClientForm
        defaultValues={{ badge }}
        submitAction={
          (data) => submitClient(data)
            .then(() => {
              badge ? navigate('/dashboard/sessions/subscription', { state: { badge }, replace: true }) : navigate(-1); 
            })
        }
        submitText="Adauga client"
        onSubmitStateUpdate={setIsSubmitting}
      />
    </AppDialog>
  )
}
