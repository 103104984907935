import { TopMenu } from "../../components/TopMenu";
import { Card, Table, TableContainer, TableBody, TableRow, TableCell, Typography } from "@mui/material";
import TableColumns from "../../components/TableColumns";
import EditIcon from '@mui/icons-material/Edit';
import OPTIONS from '../../constants/options';
import { useNavigate, useOutlet } from "react-router-dom";
import { useDashboardProvider } from "../../hooks/useDashboardProvider";

const TABLE_HEAD = [
  { id: 'option', label: 'Optiune', alignRight: false },
  { id: 'value', label: 'Valoare' },
  { id: 'edit' }
];

export const OptionsPage = () => {
  const { options } = useDashboardProvider();
  const navigate = useNavigate();
  const outlet = useOutlet();

  return (
    <>
      <TopMenu title="Optiuni">
      </TopMenu>
      <Card sx={{ display: 'flex', flexDirection: 'column', minHeight: 0 }}>
        <TableContainer sx={{ minWidth: 800, flexGrow: 1 }}>
          <Table stickyHeader>
            <TableColumns
              headLabel={TABLE_HEAD}
            />
            <TableBody>
              {(options || []).map(option =>
                <TableRow hover key={option.id} tabIndex={-1} onClick={() => navigate(`/dashboard/manager/options/${option.id}`)}>
                  <TableCell component="th" scope="row">
                    <Typography variant="subtitle2" noWrap>
                      {OPTIONS[option.type]}
                    </Typography>
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {option.value}
                  </TableCell>
                  <TableCell align="right">
                    <EditIcon />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
      {outlet}
    </>
  );
};
