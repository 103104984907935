import { TopMenu } from "../../components/TopMenu";
import { Button, Card, Table, TableContainer, TableBody, TableRow, TableCell, Typography } from "@mui/material";
import { useNavigate, useOutlet } from "react-router-dom";
import { useManagerProvider } from "../../hooks/useManagerProvider";
import TableColumns from "../../components/TableColumns";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { usePreloader } from "../../hooks/usePreloader";
import { useSnackbar } from "notistack";
import { useConfirmationDialog } from "../../hooks/useConfirmationDialog";
import API from "../../api/client";

const TABLE_HEAD = [
  { id: 'name', label: 'Nume', alignRight: false },
  { id: 'accessGroup', label: 'Grup Access' },
  { id: 'edit' },
  { id: 'delete' }
];

export const ZonesPage = () => {
  const { zones, setZones } = useManagerProvider();
  const navigate = useNavigate();
  const outlet = useOutlet();
  const { showPreloader } = usePreloader();
  const { enqueueSnackbar } = useSnackbar();
  const { showDialog } = useConfirmationDialog();
  
  const deleteZone = (id) => {
    showPreloader(true);

    API.delete(`admin/zones/${id}`)
      .then(() => {
        setZones(zones.filter(zone => zone.id !== id))
        enqueueSnackbar(`Zona a fost stearsa cu succes`, { variant: 'success' })
      })
      .catch((error) => enqueueSnackbar(`Zona nu a putut fi stearsa: ${error.message}`, { variant: 'error' }))
      .finally(() => showPreloader(false))
  } 

  return (
    <>
      <TopMenu title="Zone">
        <Button variant="contained" startIcon={<AddIcon />} onClick={() => navigate('/dashboard/manager/zones/new')}>
          Zona Noua
        </Button>
      </TopMenu>
      <Card sx={{ display: 'flex', flexDirection: 'column', minHeight: 0 }}>
        {zones &&
          <TableContainer sx={{ minWidth: 800, flexGrow: 1 }}>
            <Table stickyHeader>
              <TableColumns
                headLabel={TABLE_HEAD}
                rowCount={zones.length}
              />
              <TableBody>
                {zones.map((zone) => {
                  return (
                    <TableRow hover key={zone.id} tabIndex={-1}>
                      <TableCell component="th" scope="row">
                        <Typography variant="subtitle2" noWrap>
                          {zone.name}
                        </Typography>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Typography variant="subtitle2" noWrap>
                          {zone.accessGroup}
                        </Typography>
                      </TableCell>
                      <TableCell onClick={() => navigate(`/dashboard/manager/zones/${zone.id}`)}>
                        <Typography sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '5px' }}>
                          <EditIcon />
                        </Typography>
                      </TableCell>
                      <TableCell onClick={() => showDialog({
                        title: 'Stergeti zona',
                        message: `Confirmati stergerea zonei - ${zone.name}?`,
                        onConfirm: () => deleteZone(zone.id)
                      })}>
                        <Typography color="error" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '5px' }}>
                          <RemoveCircleIcon />
                        </Typography>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        }
      </Card>
      {outlet}
    </>
  );
};
