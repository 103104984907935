import { Card, Stack, Button, Typography, Menu, MenuItem } from '@mui/material';
import { useCallback, useState } from 'react';
import { useAuth } from '../hooks/useAuth';
import { useNavigate } from 'react-router-dom';

import MenuIcon from '@mui/icons-material/Menu';
import LoginIcon from '@mui/icons-material/Login';
import PersonIcon from '@mui/icons-material/Person';
import TollIcon from '@mui/icons-material/Toll';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import SettingsIcon from '@mui/icons-material/Settings';

export const TopMenu = ({ title, children }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { user } = useAuth();
  const navigate = useNavigate();
  const open = Boolean(anchorEl);
  const handleClick = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);
  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <>
      <Card sx={{ display: 'flex', flexDirection: 'row', padding: '20px', marginBottom: '20px', justifyContent: 'space-between', minHeight: '90px'}}>
        <Stack direction="row" alignItems="center">
          <Button 
            sx={{ display: user?.roles?.includes('ROLE_MANAGER') ? '' : 'none' }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
          >
            <MenuIcon />
          </Button>
          <Typography variant="h4" sx={{ paddingLeft: '10px' }}>
            {title}
          </Typography>
        </Stack>
        <Stack direction="row" spacing={2}>
          {children}
        </Stack>
      </Card>

      <Menu
        id="admin-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={() => navigate('/dashboard/sessions')} sx={{ padding: '15px' }}><LoginIcon sx={{ marginRight: '10px' }} /> Intrari</MenuItem>
        <MenuItem onClick={() => navigate('/dashboard/manager/users')} sx={{ padding: '15px' }}><PersonIcon sx={{ marginRight: '10px' }} /> Utilizatori</MenuItem>
        <MenuItem onClick={() => navigate('/dashboard/manager/zones')} sx={{ padding: '15px' }}><MeetingRoomIcon sx={{ marginRight: '10px' }} /> Zone Acces</MenuItem>
        <MenuItem onClick={() => navigate('/dashboard/manager/entries')} sx={{ padding: '15px' }}><TollIcon sx={{ marginRight: '10px' }} /> Manager Intrari</MenuItem>
        <MenuItem onClick={() => navigate('/dashboard/manager/options')} sx={{ padding: '15px' }}><SettingsIcon sx={{ marginRight: '10px' }} /> Optiuni</MenuItem>
        <MenuItem onClick={() => navigate('/dashboard/manager/payments')} sx={{ padding: '15px' }}><AttachMoneyIcon sx={{ marginRight: '10px' }} /> Raport Incasari</MenuItem>
      </Menu>
    </>
  )
};
