import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import API from "../../../api/client";
import { AppDialog } from "../../../components/AppDialog";
import { OptionForm } from "../../../components/OptionForm";
import { useDashboardProvider } from "../../../hooks/useDashboardProvider";
import { usePreloader } from "../../../hooks/usePreloader";

export const EditOptionPage = () => {
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [option, setOption] = useState();
  const { options, setOptions } = useDashboardProvider();
  const { id } = useParams();
  const { showPreloader } = usePreloader();

  useEffect(() => {
    showPreloader(true);

    API.get(`admin/options/${id}`)
      .then(response => setOption(response.data))
      .catch(error => {
        enqueueSnackbar(`Optiunea nu exista sau nu a putut fi incarcata: ${error.message}`, { variant: 'error' });
      })
      .finally(() => showPreloader(false))
  }, [id, enqueueSnackbar, showPreloader]);

  const submitOption = (data) => {
    return API.put(`admin/options/${option.id}`, data)
      .then(response => {
        const updatedOptionId = response.data.id;

        setOptions(options.map(option => option.id === updatedOptionId ? response.data : option));
        enqueueSnackbar(`Optiunea a fost modificata cu succes`, { variant: 'success' });
      })
      .catch(error => {
        enqueueSnackbar(`Optiunea nu a putut fi modificata: ${error.message}`, { variant: 'error' });

        throw error;
      });
  }

  return (
    <AppDialog title="Modifica optiunea" preventClose={isSubmitting}>
      {option &&
        <OptionForm defaultValues={option} submitAction={(data) => submitOption(data).then(() => navigate(-1))} submitText="Modifica optiunea" onSubmitStateUpdate={setIsSubmitting} />
      }
    </AppDialog>
  );
};
