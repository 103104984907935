import { useNavigate, useParams } from "react-router-dom";
import API from '../../../api/client';
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { AppDialog } from "../../../components/AppDialog";
import { usePreloader } from "../../../hooks/usePreloader";
import { EndSessionForm } from "../../../components/EndSessionForm";
import { serialize } from "../../../serializers/Session";

export const EndSessionPage = () => {
  const [session, setSession] = useState();
  const { id } = useParams();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { showPreloader } = usePreloader();

  useEffect(() => {
    showPreloader(true);

    API.get(`sessions/${id}`)
      .then(response => setSession(response.data))
      .catch(error => {
        enqueueSnackbar(`Intrarea nu exista sau nu a putut fi incarcata: ${error.message}`, { variant: 'error' });

        throw error;
      })
      .finally(() => showPreloader(false))
  }, [id, enqueueSnackbar, showPreloader]);

  const endSession = (data) => {
    return API.put(`sessions/${session.id}`, serialize({ ...data, active: false }))
      .then(() => enqueueSnackbar(`Intrarea a fost inchisa cu succes`, { variant: 'success' }))
      .catch(error => {
        enqueueSnackbar(`Intrarea nu a putut fi modificata: ${error.message}`, { variant: 'error' });

        throw error;
      });
  }

  return (
    <AppDialog title="Inchidere intrare" preventClose={isSubmitting}>
      {session &&
        <EndSessionForm defaultValues={session} submitAction={(data) => endSession(data).then(() => navigate(-1))} submitText="Inchide intrarea" onSubmitStateUpdate={setIsSubmitting} />
      }
    </AppDialog>
  );
};
