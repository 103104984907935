import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useSnackbar } from "notistack";
import API from "../../../api/client";
import { ZoneForm } from "../../../components/ZoneForm";
import { useManagerProvider } from "../../../hooks/useManagerProvider";
import { AppDialog } from "../../../components/AppDialog";

export const NewZonePage = () => {
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { zones, setZones } = useManagerProvider();

  const submitZone = (data) => {
    return API.post('admin/zones', { ...data, accessGroup: Number(data.accessGroup) })
      .then(response => {
        setZones([ ...zones, response.data ]);
        enqueueSnackbar(`Zona a fost creata cu succes`, { variant: 'success' });
      })
      .catch(error => {
        enqueueSnackbar(`Zona nu a putut fi creata: ${error.message}`, { variant: 'error' });

        throw error;
      });
  }

  return (
    <AppDialog title="Zona noua" preventClose={isSubmitting}>
      <ZoneForm submitAction={(data) => submitZone(data).then(() => navigate(-1))} submitText="Adauga zona" onSubmitStateUpdate={setIsSubmitting} />
    </AppDialog>
  );
};
